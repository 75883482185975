/* eslint-disable no-await-in-loop */
import {
  useRef, useState, useEffect, useContext, useCallback,
} from 'react';
import AppContext from '../../providers/authProvider';
import api from '../../api/req';
import SettingsContext from '../../providers/settingsProvider';

const useLister = ({ url, initialFilter = {}, overrideFilter = {} }) => {
  const iif = useRef(initialFilter);
  const ovf = useRef(overrideFilter);

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [options, setOptions] = useState(null);
  const [items, setItems] = useState([]);
  const [next, setNext] = useState([]);
  const [previous, setPrev] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const count = useRef(0);
  const [search, setSearch] = useState('');
  const loaded = !!options;

  const { settings, onSetSettings } = useContext(SettingsContext);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    const inFilter = Object.keys(iif.current).reduce((R, k) => ({
      ...R,
      [k]: {
        value: iif.current[k],
        use: !!iif.current[k],
      },
    }), {});
    if (settings && settings[url] && settings[url].filters) {
      setFilter({ ...settings[url].filters, ...inFilter });
    } else {
      setFilter(inFilter);
    }
  }, [settings, url]);
  const refedFilter = useRef();
  refedFilter.current = filter;

  useEffect(
    () => () => onSetSettings(url, { filters: refedFilter.current }),
    [onSetSettings, url],
  );

  const { auth } = useContext(AppContext);

  const processOptions = useCallback(
    async () => {
      const r = await api.options(url, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [auth, url],
  );

  const load = useCallback(
    async (_page, _search, _filter) => {
      const qParam = {
        ...(_page === 1 ? {} : { page: _page }),
        ...(!_search ? {} : { search: _search }),
        ..._filter,
        ...ovf.current,
      };
      const resp = await api.get(url, auth, qParam);
      if (!resp.ok) throw new Error(`${resp.status} ${resp.statusText}`);
      return resp.json();
    },
    [auth, url],
  );

  const loader = useCallback(
    () => {
      setLoading(true);
      setErr(null);
      const appliedFilter = Object.keys(filter)
        .filter((k) => filter[k] && !!filter[k].use)
        .reduce((R, k) => ({ ...R, [k]: filter[k].value }), {});
      load(currentPage, search, appliedFilter)
        .then((d) => {
          const usePagination = d.count === 0 || d.count;
          setItems(usePagination ? d.results : d);
          setNext(d.next);
          setPrev(d.previous);
          count.current = usePagination ? d.count : d.length;
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [currentPage, filter, load, search],
  );

  useEffect(() => {
    if (!options) {
      processOptions()
        .then((d) => setOptions(d))
        .catch((e) => setErr(e.message));
    }
  }, [options, processOptions]);

  useEffect(() => {
    if (options) loader();
  }, [loader, options]);

  const nextPageHandler = useCallback(
    () => {
      if (next) {
        setCurrentPage((o) => o + 1);
      }
    },
    [next],
  );

  const prevPageHandler = useCallback(
    () => {
      if (previous) setCurrentPage((o) => o - 1);
    },
    [previous],
  );

  const searchHandler = useCallback(
    (searchString) => {
      setSearch(searchString);
    },
    [],
  );

  const setFilterHandler = useCallback(
    (flt) => {
      setFilter((o) => ({ ...o, ...flt }));
    },
    [],
  );

  return {
    data: items,
    fields: options ? options.actions.GET : {},
    options,
    onNextPage: nextPageHandler,
    canNextPage: !!next,
    onPrevPage: prevPageHandler,
    canPrevPage: !!previous,
    onSearch: searchHandler,
    onSetFilter: setFilterHandler,
    filter,
    count: count.current,
    err,
    setErr,
    loading,
    loaded,
  };
};

export default useLister;

export const useDict = ({ url, usePagination = false, queryParams = {} }) => {
  const q = useRef(queryParams);
  const [data, setData] = useState(null);
  const { auth } = useContext(AppContext);
  const loader = useCallback(
    async () => {
      const fetchData = [];
      let urlParams = q.current;
      let next = true;
      while (next) {
        const r = await api.get(url, auth, urlParams);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        const dd = await r.json();
        if (usePagination) {
          fetchData.push(...dd.results);
          if (dd.next) {
            urlParams = [...(new URLSearchParams(new URL(dd.next).search)).entries()]
              .reduce((R, [a, b]) => ({ ...R, [a]: b }), {});
          } else {
            next = false;
          }
        } else {
          fetchData.push(...dd);
          next = false;
        }
      }
      return fetchData;
    },
    [auth, url, usePagination],
  );
  useEffect(() => {
    if (!data) {
      loader()
        .then((d) => setData(d))
        .catch(() => setData([]));
      // loader().then((d) => setData(usePagination ? d.results : d)).catch(() => setData([]));
    }
  }, [data, loader, usePagination]);
  return {
    data: data || [],
    selectorValues: (data || []).map((p) => ({ value: p.id, display_name: p.repr })),
  };
};

export const useFilterItems = (filters, name, onSetFilter) => {
  const f = filters ? filters[name] : {};
  const use = f && f.use && !!f.value;
  const value = f ? f.value : null;
  const onToggle = useCallback(
    () => onSetFilter({ [name]: { use: !use && !!value, value } }),
    [name, onSetFilter, use, value],
  );
  const onChange = useCallback(
    (e, v) => onSetFilter({ [name]: { use: !!v, value: v } }),
    [name, onSetFilter],
  );

  return {
    use, value, onToggle, onChange,
  };
};
