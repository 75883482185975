import React, {
  createRef, forwardRef, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';

const TextInput = forwardRef(({
  value, disabled, readOnly,
  onClick, onFocus, maxLength, onChange, rows, id,
  className, errors, errorAsTooltip,
  prepend, append, onBlur, monospace, size, placeholder,
}, oldRef) => {
  const ref = oldRef || createRef();
  const oldSelectionStart = useRef({ start: null, end: null });
  useEffect(
    () => {
      // eslint-disable-next-line no-constant-condition
      if (value || true) {
        ref.current.setSelectionRange(
          oldSelectionStart.current.start,
          oldSelectionStart.current.end,
        );
      }
    },
    [ref, value],
  );
  const changeHandler = (e) => {
    const newValue = e.target.value;
    if (!readOnly && (!maxLength || newValue.length <= maxLength)) {
      oldSelectionStart.current = {
        start: ref.current.selectionStart,
        end: ref.current.selectionEnd,
      };
      onChange(e, newValue);
    }
  };

  return (
    <InputGroup className={className} size={size}>
      {prepend}
      <FormControl
        as="textarea"
        id={id}
        value={value || ''}
        onChange={changeHandler}
        disabled={disabled}
        readOnly={readOnly}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        rows={rows}
        ref={ref}
        isInvalid={!!errors && !!errors.length}
        className={classNames({ 'font-monospace': monospace })}
        placeholder={placeholder}
      />
      {append}
      {errors && (
      <Form.Control.Feedback type="invalid" tooltip={errorAsTooltip}>
        {errors.join(', ')}
      </Form.Control.Feedback>
      )}
    </InputGroup>
  );
});

TextInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  rows: PropTypes.number,
  className: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  errorAsTooltip: PropTypes.bool,
  prepend: PropTypes.node,
  append: PropTypes.node,
  monospace: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  placeholder: PropTypes.string,
};

TextInput.defaultProps = {
  id: '',
  value: '',
  disabled: false,
  readOnly: false,
  maxLength: 0,
  onClick: null,
  onFocus: null,
  onBlur: null,
  rows: 3,
  className: null,
  errors: null,
  errorAsTooltip: false,
  prepend: null,
  append: null,
  onChange: () => null,
  monospace: false,
  size: 'sm',
  placeholder: '',
};

export default TextInput;
