import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import { Button, Collapse } from 'react-bootstrap';
import PeopleEditor from './editorPage';
import useEditor from '../../BasicEditor/hook/editor';
import EditorContainer from '../../BasicEditor/editorContainer';
import { Loader } from '../../../components/Styled/Misc';
import api from '../../../api/req';
import AppContext from '../../../providers/authProvider';
import addr from '../../../const/urls';

function SmallEditor({ id, onSave }) {
  useEffect(() => {
    const link = document.createElement('link');
    link.href = `${addr}/static/css/pdf_gen.css`;
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.className = 'theme-stylesheet';
    const head = document.getElementsByTagName('head')[0];
    const firstStyle = head.querySelector('style[type="text/css"]');
    if (firstStyle) {
      head.insertBefore(link, firstStyle);
    } else {
      head.appendChild(link);
    }
  }, []);

  const {
    isNew, data, changed,
    fields, fieldErrors, actions, loading, systemErrors, nonFieldErrors, currentData,
    makeErrors,
  } = useEditor({
    id,
    backendURL: '/api/cat/peoples/',
    onSaveCallBack: onSave,
  });
  const authorText = data.author ? `${data.author.first_name} ${data.author.last_name} (${data.author.username})` : 'some (anonymous)';
  const title = useMemo(
    () => {
      if (isNew) return 'Application form';
      const createdAt = new Date(data.created_at);
      const created = createdAt.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
      return `${data.name} ${data.family_name} (${data.email}) / Created by ${authorText} on ${created}`;
    },
    [authorText, data.created_at, data.email, data.family_name, data.name, isNew],
  );
  const [previewMode, setPreviewMode] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [previewHtml, setPreviewHtml] = useState('');
  const { auth } = useContext(AppContext);
  useEffect(
    () => {
      const loader = async () => {
        const r = await api.post('/api/cat/peoples/generate_preview_pdf/', auth, currentData.current);
        if (!r.ok && r.status !== 400) throw new Error('Failed to generate preview');
        else if (!r.ok && r.status === 400) {
          const errData = await r.json();
          makeErrors(errData);
          setPreviewMode(false);
          throw new Error('Bad data');
        } else {
          return r.json();
        }
      };
      setPreviewHtml('');
      if (previewMode) {
        setPreviewLoading(true);
        loader()
          .then((d) => {
            const repl = [
              { r: /src="([0-9a-zA-Z/_.]*)"/gm, pl: `src="${process.env.REACT_APP_API_SERVER_ADDR}$1"` },
              { r: /href="([0-9a-zA-Z/_.]*)"/gm, pl: `href="${process.env.REACT_APP_API_SERVER_ADDR}$1"` },
            ];
            const newT = repl.reduce((R, r) => R.replace(r.r, r.pl), d.result);
            setPreviewHtml(newT);
          })
          .catch((e) => console.log(e))
          .finally(() => setPreviewLoading(false));
      }
    },
    [auth, currentData, makeErrors, previewMode],
  );
  if (!fields) return null;
  return (
    <>
      <Collapse in={previewMode} dimension="width">
        <div>
          {previewLoading && (<Loader />)}
          <div className="h-50 w-100" dangerouslySetInnerHTML={{ __html: previewHtml }} />
          <div className="d-flex gap-2 justify-content-center">
            <Button variant="primary" size="" onClick={() => actions.onSave()}>
              <FontAwesomeIcon icon={faSave} className="me-2" />
              Save
            </Button>
            <Button variant="secondary" size="" onClick={() => setPreviewMode(false)}>
              <FontAwesomeIcon icon={faEdit} className="me-2" />
              Edit
            </Button>
          </div>
        </div>
      </Collapse>
      <Collapse in={!previewMode} dimension="width" unmountOnExit={false}>
        <div>
          <EditorContainer
            title={title}
            isLoading={loading}
            fields={fields}
            err={systemErrors}
            nonFieldErrors={nonFieldErrors}
            fieldErrors={fieldErrors}
            onClearErrors={actions.onClearErrs}
            onClearNonFieldErrors={actions.onClearNonFieldErrors}
            noPaddingX
          >
            <PeopleEditor
              data={data}
              fields={fields}
              fieldErrors={fieldErrors}
              onChange={actions.onChange}
              isNew={isNew}
              changed={changed}
              onSave={() => setPreviewMode(true)}
              onDelete={actions.onDelete}
            />
          </EditorContainer>
        </div>
      </Collapse>
    </>
  );
}

SmallEditor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
};

SmallEditor.defaultProps = {
  onSave: null,
};

export default SmallEditor;
