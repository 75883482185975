import React, {
  memo, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Alert, Badge,
  Button, Col, Collapse, Container, InputGroup, ListGroup, Nav, Row, ToggleButton, ToggleButtonGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile, faFilter, faIdCard, faList,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import {
  SearchInput, MultipleSelector, Selector, NumberInput,
} from '../../../components/Controls';
import {
  CountrySelector, PositionSelector, WFStatusSelector, JobSelector, UserSelector, CompanySelector,
} from '../../SimpleSelector';
import { useDict, useFilterItems } from '../../BaseLister/hook';
import PeopleItem from './item';
import SettingsContext from '../../../providers/settingsProvider';
import PeopleItemRow from './itemrow';

function PeoplesList({
  data, fields, onSearch, filter, onSetFilter, count, showFilters, onSetShowFilters,
}) {
  const [view, setView] = useState(null);
  const refedView = useRef();
  refedView.current = view;
  const { settings, onSetSettings } = useContext(SettingsContext);

  useEffect(() => {
    const s = settings['/api/cat/peoples/viewmode/'];
    if (s && !view) setView(s);
  }, [settings, view]);

  useEffect(
    () => () => onSetSettings('/api/cat/peoples/viewmode/', refedView.current),
    [onSetSettings],
  );

  const navigate = useNavigate();
  const languages = useDict({ url: '/api/cat/language/' });
  const countries = useDict({ url: '/api/cat/country/' });
  const positions = useDict({ url: '/api/cat/position/' });
  const labels = useDict({ url: '/api/cat/labels/' });
  const wfStatuses = useDict({ url: '/api/cat/workflows/' });
  const jobs = useDict({
    url: '/api/cat/jobs/',
    usePagination: true,
    queryParams: { status: 'O' },
  });
  const authors = useDict({ url: '/api/core/user/' });
  const authorsData = useMemo(
    () => [
      ...authors.data,
      { id: 'null', repr: 'self' },
    ],
    [authors.data],
  );
  const companies = useDict({ url: '/api/cat/companies/' });

  const pinFilter = useFilterItems(filter, 'id', onSetFilter);
  const languageFilter = useFilterItems(filter, 'languages', onSetFilter);
  const citizenshipFilter = useFilterItems(filter, 'citizenship', onSetFilter);
  const positionsFilter = useFilterItems(filter, 'positions', onSetFilter);
  const labelsFilter = useFilterItems(filter, 'labels', onSetFilter);
  const labelsExcludeFilter = useFilterItems(filter, 'labels__exclude', onSetFilter);
  const wfFilter = useFilterItems(filter, 'workflow_status', onSetFilter);
  const jobFilter = useFilterItems(filter, 'job', onSetFilter);
  const authorFilter = useFilterItems(filter, 'author', onSetFilter);
  const citizenEUFilter = useFilterItems(filter, 'citizen_eu', onSetFilter);
  const preferencedCompanyFilter = useFilterItems(filter, 'preferenced_company', onSetFilter);
  const genderFilter = useFilterItems(filter, 'gender', onSetFilter);
  return (
    <Container fluid>
      <div className="position-sticky fixed-under-menu bg-white">
        <div className="d-flex align-items-start">
          <div className="d-flex align-items-start">
            <h1 className="text-primary me-2">
              Candidate list
            </h1>
            <Badge>{count}</Badge>
          </div>
          <ToggleButtonGroup
            className="ms-auto"
            value={view}
            type="radio"
            name="viewMode"
          >
            <ToggleButton value="list" title="List view" onClick={() => setView('list')}>
              <FontAwesomeIcon icon={faList} />
            </ToggleButton>
            <ToggleButton value="card" title="Card view" onClick={() => setView('card')}>
              <FontAwesomeIcon icon={faIdCard} />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Nav className="mb-2 pb-2 border-bottom d-flex flex-column flex-sm-row w-100 align-items-center gy-2 gy-sm-0 gap-2">
          <div className="d-flex gap-2">
            <Button variant="outline-primary" onClick={() => navigate('/candidates/create')} size="sm">
              <FontAwesomeIcon icon={faFile} className="me-2" />
              Create new
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => onSetShowFilters((o) => !o)}
              size="sm"
              active={showFilters}
              className="d-block d-lg-none"
            >
              <FontAwesomeIcon icon={faFilter} className="me-2" />
              Filters
            </Button>
          </div>
          <div className="ms-sm-auto align-self-stretch">
            <SearchInput onSearch={onSearch} />
          </div>
        </Nav>
      </div>
      <Row className="flex-column-reverse flex-lg-row">
        <Col lg={8} xl={9}>
          <Row className="gy-2">
            {data.length ? data.map((row) => (view === 'card' ? (
              <Col key={row.id} xs={12} sm={6} md={6} lg={6} xl={6} xxl={4}>
                <PeopleItem row={row} fields={fields} />
              </Col>
            ) : (
              <Col key={row.id} xs={12}>
                <PeopleItemRow row={row} fields={fields} />
              </Col>
            ))) : (
              <Alert variant="info" className="w-100">
                <Alert.Heading>Oops</Alert.Heading>
                There&apos;s nothing here
              </Alert>
            )}
          </Row>
        </Col>
        <Col lg={4} xl={3}>
          <Collapse in={showFilters}>
            <div>
              <h3>Filters</h3>
              <ListGroup>
                <ListGroup.Item>
                  <NumberInput
                    prepend={(
                      <>
                        <InputGroup.Text>Pin number</InputGroup.Text>
                        <InputGroup.Checkbox checked={pinFilter.use} onChange={pinFilter.onToggle} />
                      </>
                      )}
                    value={pinFilter.value}
                    onChange={pinFilter.onChange}
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <MultipleSelector
                    prepend={(
                      <>
                        <InputGroup.Text>{fields.languages.label}</InputGroup.Text>
                        <InputGroup.Checkbox checked={languageFilter.use} onChange={languageFilter.onToggle} />
                      </>
                      )}
                    value={languageFilter.value}
                    onChange={languageFilter.onChange}
                    values={languages.data.map((l) => ({ value: l.id, display_name: l.repr }))}
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <CountrySelector
                    prepend={(
                      <>
                        <InputGroup.Text>{fields.citizenship.label}</InputGroup.Text>
                        <InputGroup.Checkbox checked={citizenshipFilter.use} onChange={citizenshipFilter.onToggle} />
                      </>
                      )}
                    value={citizenshipFilter.value}
                    onChange={citizenshipFilter.onChange}
                    preloadedData={countries.data}
                    required
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <PositionSelector
                    prepend={(
                      <>
                        <InputGroup.Text>{fields.positions.label}</InputGroup.Text>
                        <InputGroup.Checkbox checked={positionsFilter.use} onChange={positionsFilter.onToggle} />
                      </>
                      )}
                    value={positionsFilter.value}
                    onChange={positionsFilter.onChange}
                    preloadedData={positions.data}
                    required
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <MultipleSelector
                    prepend={(
                      <>
                        <InputGroup.Text>Labels (in)</InputGroup.Text>
                        <InputGroup.Checkbox checked={labelsFilter.use} onChange={labelsFilter.onToggle} />
                      </>
                      )}
                    value={labelsFilter.value}
                    onChange={labelsFilter.onChange}
                    values={labels.data.map((l) => ({ value: l.id, display_name: l.repr }))}
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <MultipleSelector
                    prepend={(
                      <>
                        <InputGroup.Text>Labels (not in)</InputGroup.Text>
                        <InputGroup.Checkbox checked={labelsExcludeFilter.use} onChange={labelsExcludeFilter.onToggle} />
                      </>
                      )}
                    value={labelsExcludeFilter.value}
                    onChange={labelsExcludeFilter.onChange}
                    values={labels.data.map((l) => ({ value: l.id, display_name: l.repr }))}
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <WFStatusSelector
                    prepend={(
                      <>
                        <InputGroup.Text>Workflow status</InputGroup.Text>
                        <InputGroup.Checkbox checked={wfFilter.use} onChange={wfFilter.onToggle} />
                      </>
                      )}
                    value={wfFilter.value}
                    onChange={wfFilter.onChange}
                    preloadedData={wfStatuses.data}
                    required
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <JobSelector
                    prepend={(
                      <>
                        <InputGroup.Text>Job</InputGroup.Text>
                        <InputGroup.Checkbox checked={jobFilter.use} onChange={jobFilter.onToggle} />
                      </>
                      )}
                    value={jobFilter.value}
                    onChange={jobFilter.onChange}
                    preloadedData={jobs.data}
                    required
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <UserSelector
                    prepend={(
                      <>
                        <InputGroup.Text>{fields.author.label}</InputGroup.Text>
                        <InputGroup.Checkbox checked={authorFilter.use} onChange={authorFilter.onToggle} />
                      </>
                      )}
                    value={authorFilter.value}
                    onChange={authorFilter.onChange}
                    preloadedData={authorsData}
                    required
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Selector
                    prepend={(
                      <>
                        <InputGroup.Text>{fields.citizen_eu.label}</InputGroup.Text>
                        <InputGroup.Checkbox checked={citizenEUFilter.use} onChange={citizenEUFilter.onToggle} />
                      </>
                      )}
                    value={citizenEUFilter.value}
                    onChange={citizenEUFilter.onChange}
                    values={fields.citizen_eu.choices}
                    required
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <CompanySelector
                    prepend={(
                      <>
                        <InputGroup.Text>Applied for</InputGroup.Text>
                        <InputGroup.Checkbox checked={preferencedCompanyFilter.use} onChange={preferencedCompanyFilter.onToggle} />
                      </>
                      )}
                    value={preferencedCompanyFilter.value}
                    onChange={preferencedCompanyFilter.onChange}
                    preloadedData={companies.data}
                    required
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Selector
                    prepend={(
                      <>
                        <InputGroup.Text>Gender</InputGroup.Text>
                        <InputGroup.Checkbox checked={genderFilter.use} onChange={genderFilter.onToggle} />
                      </>
                      )}
                    value={genderFilter.value}
                    onChange={genderFilter.onChange}
                    values={fields.gender.choices}
                    required
                  />
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Collapse>
        </Col>
      </Row>
    </Container>
  );
}

PeoplesList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })).isRequired,
  fields: PropTypes.shape({
    languages: PropTypes.shape({ label: PropTypes.string }),
    citizenship: PropTypes.shape({ label: PropTypes.string }),
    positions: PropTypes.shape({ label: PropTypes.string }),
    author: PropTypes.shape({ label: PropTypes.string }),
    citizen_eu: PropTypes.shape({ label: PropTypes.string, choices: PropTypes.arrayOf(PropTypes.shape({})) }),
    gender: PropTypes.shape({ label: PropTypes.string, choices: PropTypes.arrayOf(PropTypes.shape({})) }),
  }).isRequired,
  onSearch: PropTypes.func.isRequired,
  filter: PropTypes.shape({}).isRequired,
  onSetFilter: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  showFilters: PropTypes.bool.isRequired,
  onSetShowFilters: PropTypes.func.isRequired,
};

export default memo(PeoplesList);
